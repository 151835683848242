import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { defineMessages, FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5)
  }
}));

const messages = defineMessages({
  text: {
    id: "updateAvailable.text",
    defaultMessage:
      "A new update is available, close or reload the app to use it."
  },
  reload: {
    id: "updateAvailable.reload",
    defaultMessage: "reload"
  }
});

export default function UpdateNotificationWrapper(props) {
  // Use a key to force the notification open when a new update is detected.
  return <UpdateNotification key={props.updateAvailable} {...props} />;
}

function UpdateNotification({ updateAvailable, newServiceWorker }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(updateAvailable);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleReload = () => {
    if (newServiceWorker) {
      newServiceWorker.postMessage({
        type: "SKIP_WAITING"
      });
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      open={open}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={<FormattedMessage {...messages.text} />}
      action={[
        <Button
          key="reload"
          color="secondary"
          size="small"
          onClick={handleReload}
        >
          <FormattedMessage {...messages.reload} />
        </Button>,
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
}
