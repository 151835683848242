import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { createMigrate, persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer, { initialState } from "./reducers";

const migrations = {
  0: state => state, // initial version, no-op
  1: state => {
    // migration to keep only device state
    let newState = {
      ...state,
      config: { limits: { ...state.limits } }
    };
    delete newState.limits;
    return newState;
  },
  2: state => {
    let { config, ...rest } = state;
    return {
      ...rest,
      config: {
        ...config,
        button1: initialState.config.button1,
        button2: initialState.config.button2
      }
    };
  }
};

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["current", "system"],
  version: 2,
  migrate: createMigrate(migrations)
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
  let persistor = persistStore(store);
  return { store, persistor };
}
