import React from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import messages_en from "../locales/en.json";
import messages_fr from "../locales/fr.json";

export const SUPPORTED_LOCALES = ["en", "fr"];

function IntlContainer(props) {
  const localeData = {
    en: messages_en,
    fr: messages_fr
  };

  // Define user's language. Different browsers have the user locale defined
  // on different fields on the `navigator` object, so we make sure to account
  // for these different by checking all of them
  const language =
    props.locale ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

  // Split locales with a region code
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

  // Try full locale, try locale without region code, fallback to 'en'
  const messages =
    localeData[language] ||
    localeData[languageWithoutRegionCode] ||
    localeData.en;

  return (
    <IntlProvider locale={language} messages={messages}>
      {props.children}
    </IntlProvider>
  );
}

const mapStateToProps = state => {
  return {
    locale: state.config.locale
  };
};

export default connect(mapStateToProps)(IntlContainer);
