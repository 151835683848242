import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { defineMessages, FormattedMessage } from "react-intl";

const styles = theme => ({
  title: {
    marginTop: "0.5em",
    marginBottom: "1em",
    fontFamily: "Permanent Marker, Roboto, sans-serif"
  }
});

const messages = defineMessages({
  title: {
    id: "header.title",
    defaultMessage: "Men talk too much."
  }
});

class Header extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={12}>
        <Typography variant="h4" align="center" className={classes.title}>
          <FormattedMessage {...messages.title} />
        </Typography>
      </Grid>
    );
  }
}

export default withStyles(styles)(Header);
