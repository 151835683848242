import React, { Component } from "react";

class Duration extends Component {
  render() {
    const minutes = Math.trunc(this.props.value / 60);
    const seconds = Math.trunc(this.props.value % 60);
    const secondsText = String(seconds).padStart(2, "0");
    return <span>{`${minutes}:${secondsText}`}</span>;
  }
}

export default Duration;
