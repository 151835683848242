import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import grey from "@material-ui/core/colors/grey";
import { defineMessages, FormattedMessage } from "react-intl";

const styles = {
  root: {
    color: grey[600],
    marginRight: 0
  },
  icon: {
    color: grey[600]
  }
};

const messages = defineMessages({
  text: {
    id: "isorganizerbutton.text",
    defaultMessage: "Organizer"
  }
});

function IsOrganizerButton(props) {
  const { classes, disabled, isOrganizer, setOrganizer } = props;

  const handleChange = e => {
    const value = e.target.checked;
    setOrganizer(value);
  };

  return (
    <FormControlLabel
      disabled={disabled}
      className={classes.root}
      control={
        <Checkbox
          checked={isOrganizer}
          onChange={handleChange}
          size="small"
          value="isorganizer"
          icon={
            <CheckBoxOutlineBlankIcon
              className={disabled ? null : classes.icon}
            />
          }
          checkedIcon={
            <CheckBoxIcon className={disabled ? null : classes.icon} />
          }
        />
      }
      label={<FormattedMessage {...messages.text} />}
    />
  );
}

export default withStyles(styles)(IsOrganizerButton);
