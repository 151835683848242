import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";
import Duration from "./Duration";
import ResetButton from "../containers/ConnectedResetButton";
import ExportButton from "../containers/ConnectedExportButton";

const styles = {
  root: {
    marginTop: "2em"
  },
  buttons: {
    marginTop: "0.5em"
  },
  listItem: {
    "& .MuiTypography-body2": {
      fontSize: "0.8rem"
    }
  }
};

const messages = defineMessages({
  nobody: {
    id: "stats.nobody",
    defaultMessage: "Nobody has spoken yet."
  },
  header: {
    id: "stats.header",
    defaultMessage: "Men have talked:"
  },
  timePercent: {
    id: "stats.timePercent",
    defaultMessage: "{value}% of the time"
  },
  speechesPercent: {
    id: "stats.speechesPercent",
    defaultMessage: "{value}% of the opportunities"
  },
  speakersPercent: {
    id: "stats.speakersPercent",
    defaultMessage: "{value}% of the speakers"
  },
  speechesDuration: {
    id: "stats.speechesDuration",
    defaultMessage: "Mean speech duration:"
  },
  forOthers_notman: {
    id: "stats.forOthers.notman",
    defaultMessage: "For others:"
  },
  forOthers_woman: {
    id: "stats.forOthers.woman",
    defaultMessage: "For women:"
  }
});

class Stats extends Component {
  render() {
    const { classes } = this.props;
    const totalSpeakers =
      this.props.stats[this.props.button1].speakers +
      this.props.stats[this.props.button2].speakers;
    return (
      <div className={classes.root}>
        <StatsValues {...this.props} />

        {totalSpeakers > 0 && (
          <Grid
            container
            justify="center"
            spacing={2}
            className={classes.buttons}
          >
            <Grid item xs={6}>
              <Typography align="left">
                <ExportButton />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right">
                <ResetButton />
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

class StatsValues extends Component {
  render() {
    const { classes } = this.props;
    const duration1 = this.props.stats[this.props.button1].duration;
    const duration2 = this.props.stats[this.props.button2].duration;
    const speeches1 = this.props.stats[this.props.button1].speeches;
    const speeches2 = this.props.stats[this.props.button2].speeches;
    const speakers1 = this.props.stats[this.props.button1].speakers;
    const speakers2 = this.props.stats[this.props.button2].speakers;

    const totalSpokenTime = duration1 + duration2;
    const totalSpeeches = speeches1 + speeches2;
    const totalSpeakers = speakers1 + speakers2;
    const timePercent =
      totalSpokenTime === 0
        ? 0
        : Math.round((duration1 / totalSpokenTime) * 100);
    const speechesPercent =
      totalSpeeches === 0 ? 0 : Math.round((speeches1 / totalSpeeches) * 100);
    const speakersPercent =
      totalSpeakers === 0 ? 0 : Math.round((speakers1 / totalSpeakers) * 100);
    const firstMeanTime = Math.round(duration1 / speeches1);
    const secondMeanTime = Math.round(duration2 / speeches2);

    if (totalSpeakers === 0) {
      return (
        <Typography component="p">
          <FormattedMessage {...messages.nobody} />
        </Typography>
      );
    }
    return (
      <div>
        <Typography variant="subtitle1" component="h3">
          <FormattedMessage {...messages.header} />
        </Typography>
        <List dense disablePadding>
          <ListItem>
            <ListItemIcon>
              <Icon>schedule</Icon>
            </ListItemIcon>
            <ListItemText
              primary={this.props.intl.formatMessage(messages.timePercent, {
                value: timePercent
              })}
              secondary={
                <span>
                  (<Duration value={duration1} />/
                  <Duration value={totalSpokenTime} />)
                </span>
              }
              className={classes.listItem}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Icon>pan_tool</Icon>
            </ListItemIcon>
            <ListItemText
              primary={this.props.intl.formatMessage(messages.speechesPercent, {
                value: speechesPercent
              })}
              secondary={`(${speeches1}/${totalSpeeches})`}
              className={classes.listItem}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Icon>face</Icon>
            </ListItemIcon>
            <ListItemText
              primary={this.props.intl.formatMessage(messages.speakersPercent, {
                value: speakersPercent
              })}
              secondary={`(${speakers1}/${totalSpeakers})`}
              className={classes.listItem}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Icon>mic</Icon>
            </ListItemIcon>
            <ListItemText
              primary={
                <span>
                  <FormattedMessage {...messages.speechesDuration} />{" "}
                  {speeches1 > 0 ? <Duration value={firstMeanTime} /> : "?"}
                </span>
              }
              secondary={
                <span>
                  (
                  <FormattedMessage
                    {...messages[`forOthers_${this.props.button2}`]}
                  />{" "}
                  {speeches2 > 0 ? <Duration value={secondMeanTime} /> : "?"})
                </span>
              }
              className={classes.listItem}
            />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default injectIntl(withStyles(styles)(Stats));
