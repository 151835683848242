import React, { useState } from "react";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ConfigTimerLimit from "./ConfigTimerLimit";
import ConfigButtonLabel from "./ConfigButtonLabel";
import ConfigLocale from "./ConfigLocale";

const styles = theme => ({
  buttonField: {
    marginTop: theme.spacing(3)
  },
  title: {
    "& h2": {
      fontWeight: "600"
    }
  },
  actions: {
    marginTop: theme.spacing(2)
  },
  actionButton: {
    fontWeight: "bold"
  }
});

const messages = defineMessages({
  title: {
    id: "settings.title",
    defaultMessage: "Settings"
  },
  close: {
    id: "settings.close",
    defaultMessage: "Close"
  }
});

function Settings(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { classes } = props;

  return (
    <React.Fragment>
      <Typography align="center" className={classes.buttonField}>
        <Button onClick={handleClickOpen} size="small">
          <Icon style={{ marginRight: "0.3em" }}>settings</Icon>
          <FormattedMessage {...messages.title} />
        </Button>
      </Typography>
      <Dialog
        aria-labelledby="settings-title"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="settings-title" className={classes.title}>
          <FormattedMessage {...messages.title} />
        </DialogTitle>
        <DialogContent>
          <ConfigTimerLimit config={props.config} onChange={props.onChange} />
          <ConfigButtonLabel config={props.config} onChange={props.onChange} />
          <ConfigLocale config={props.config} onChange={props.onChange} />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.actionButton}
          >
            <FormattedMessage {...messages.close} />
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default injectIntl(withStyles(styles)(Settings));
