import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import orange from "@material-ui/core/colors/orange";
import Duration from "./Duration";

const styles = {
  warning: {
    color: orange[600]
  },
  barWarning: {
    backgroundColor: orange[600]
  },
  barWarningBg: {
    backgroundColor: orange[100]
  },
  card: {
    width: 200
  },
  cardContent: {
    paddingBottom: "16px !important"
  },
  progress: {
    marginTop: "15px",
    marginBottom: "-5px"
  }
};

class Timer extends Component {
  render() {
    const { classes } = this.props;
    const counter = this.props.ticker;
    let color = "initial";
    let barColor = "primary";
    let barClasses = {};
    if (this.props.maxTime) {
      if (counter > this.props.maxTime) {
        color = "error";
        barColor = "secondary";
      } else if (counter > this.props.warnTime) {
        color = "textSecondary";
        barColor = "secondary";
        barClasses = {
          barColorSecondary: classes.barWarning,
          colorSecondary: classes.barWarningBg
        };
      }
    }
    const completed = Math.min((counter / this.props.maxTime) * 100, 100);
    return (
      <div className="Timer">
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography
              variant="h5"
              component="h2"
              align="center"
              color={color}
              classes={{
                colorTextSecondary: classes.warning
              }}
            >
              <Duration value={counter} />
            </Typography>
            {this.props.maxTime ? (
              <LinearProgress
                color={barColor}
                variant="determinate"
                value={completed}
                className={classes.progress}
                classes={barClasses}
              />
            ) : null}
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(Timer);
