import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";
import ConnectedSpeakerButtons from "../containers/ConnectedSpeakerButtons";
import ConnectedAlreadyTalkedButton from "../containers/ConnectedAlreadyTalkedButton";
import ConnectedIsOrganizerButton from "../containers/ConnectedIsOrganizerButton";
import ConnectedTimer from "../containers/ConnectedTimer";
import ConnectedSettings from "../containers/ConnectedSettings";
import ConnectedTicker from "../containers/ConnectedTicker";
import ConnectedStats from "../containers/ConnectedStats";
import ConnectedUpdateNotification from "../containers/ConnectedUpdateNotification";
import Header from "./Header";
import Footer from "./Footer";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  buttons: {
    marginBottom: "1em"
  },
  timer: {
    marginTop: "1em",
    marginBottom: "1em"
  },
  stats: {}
});

const messages = defineMessages({
  title: {
    id: "app.title",
    defaultMessage: "Who's talking?"
  }
});

class App extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className="App">
        <CssBaseline />
        <Grid container className={classes.root} justify="center">
          <Header />

          <Grid item xs={12}>
            <Grid
              container
              className={classes.buttons}
              justify="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h6" component="h3" align="center">
                  <FormattedMessage {...messages.title} />
                </Typography>
              </Grid>
              <ConnectedSpeakerButtons />
              <Grid item xs={6}>
                <Typography align="right">
                  <ConnectedAlreadyTalkedButton />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left">
                  <ConnectedIsOrganizerButton />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container className={classes.timer} justify="center">
              <Grid item>
                <ConnectedTimer />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container className={classes.stats} justify="center">
              <Grid item>
                <ConnectedStats />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <ConnectedSettings />
          </Grid>
        </Grid>
        <ConnectedUpdateNotification />
        <ConnectedTicker />
        <Footer />
      </div>
    );
  }
}

export default injectIntl(withStyles(styles)(App));
