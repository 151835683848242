import { connect } from "react-redux";
import ExportButton from "../components/ExportButton";

const mapStateToProps = (state, ownProps) => {
  return {
    speeches: state.speeches
  };
};

const ConnectedExportButton = connect(mapStateToProps)(ExportButton);

export default ConnectedExportButton;
