import { Component } from "react";

class Ticker extends Component {
  state = {
    timer: null
  };

  componentWillUnmount() {
    this.stop();
  }

  componentDidUpdate(prevProps) {
    if (this.props.running) {
      if (prevProps.startedAt !== this.props.startedAt) {
        // restart the timer, don't just update
        this.stop();
      }
      this.start();
    } else {
      this.stop();
    }
  }

  isCounting = () => this.state.timer !== null;

  start = () => {
    if (this.isCounting()) {
      return;
    }
    this.setState({
      timer: setInterval(this.tick, 1000)
    });
  };

  stop = () => {
    if (!this.isCounting()) {
      return;
    }
    clearInterval(this.state.timer);
    this.setState({
      timer: null
    });
  };

  tick = () => {
    if (!this.isCounting() || !this.props.running) {
      return;
    }
    this.props.tick();
  };

  render() {
    return null;
  }
}

export default Ticker;
