import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { defineMessages, FormattedMessage } from "react-intl";

const styles = theme => ({
  root: {
    borderTop: "1px solid grey",
    marginTop: "5em",
    paddingTop: "1em",
    color: "grey"
  }
});

const messages = defineMessages({
  text1: {
    id: "footer.text1",
    defaultMessage: "Very heavily inspired by"
  },
  text2: {
    id: "footer.text2",
    defaultMessage: "Actually, it's the same thing with more stats."
  },
  viewCode: {
    id: "footer.viewCode",
    defaultMessage: "See the code"
  }
});

class Footer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root} justify="center">
        <Grid item xs={12} className={classes.title}>
          <Typography variant="caption" paragraph={true} align="center">
            <FormattedMessage {...messages.text1} />{" "}
            <a href="http://arementalkingtoomuch.com">AreMenTalkingTooMuch</a>.{" "}
            <FormattedMessage {...messages.text2} />{" "}
            <a href="https://gitlab.com/abompard/men-talk-too-much">
              <FormattedMessage {...messages.viewCode} />
            </a>
            .
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Footer);
