import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import FileSaver from "file-saver";

const messages = defineMessages({
  export: {
    id: "exportbutton.export",
    defaultMessage: "Export"
  },
  timestamp: {
    id: "exportbutton.timestamp",
    defaultMessage: "Timestamp"
  },
  category: {
    id: "exportbutton.category",
    defaultMessage: "Category"
  },
  duration: {
    id: "exportbutton.duration",
    defaultMessage: "Duration"
  },
  alreadyTalked: {
    id: "exportbutton.alreadyTalked",
    defaultMessage: "Has already talked"
  },
  isOrganizer: {
    id: "exportbutton.isOrganizer",
    defaultMessage: "Organizer"
  },
  overtime: {
    id: "exportbutton.overtime",
    defaultMessage: "Overtime"
  }
});

class ExportButton extends Component {
  makeData = () => {
    const toCSVLine = a => a.map(v => '"' + v + '"').join(",") + "\n";
    let data = [
      toCSVLine([
        this.props.intl.formatMessage(messages.timestamp),
        this.props.intl.formatMessage(messages.category),
        this.props.intl.formatMessage(messages.duration),
        this.props.intl.formatMessage(messages.alreadyTalked),
        this.props.intl.formatMessage(messages.isOrganizer),
        this.props.intl.formatMessage(messages.overtime)
      ])
    ];
    for (let i = 0; i < this.props.speeches.length; i++) {
      const speech = this.props.speeches[i];
      data.push(
        toCSVLine([
          speech.timestamp,
          speech.category,
          speech.duration,
          speech.alreadyTalked,
          speech.isOrganizer,
          speech.overtime
        ])
      );
    }
    return data;
  };

  onClick = e => {
    e.preventDefault();
    const now = new Date();
    const todayString =
      now.getFullYear() +
      "-" +
      (now.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      now
        .getDate()
        .toString()
        .padStart(2, "0");
    const blob = new Blob(this.makeData(), { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, "talk-" + todayString + ".csv");
  };

  render() {
    return (
      <Button color="default" size="small" onClick={this.onClick}>
        <Icon fontSize="inherit" style={{ marginRight: "0.3em" }}>
          open_in_new
        </Icon>
        <FormattedMessage {...messages.export} />
      </Button>
    );
  }
}

export default injectIntl(ExportButton);
