import { combineReducers } from "redux";
import {
  START_TALKING,
  STOP_TALKING,
  ALREADY_TALKED,
  IS_ORGANIZER,
  TICK,
  CHANGE_CONFIG,
  RESET,
  UPDATE_AVAILABLE
} from "./actions";

const MAX_TIME = 2 * 60 + 30; // 2:30

export const initialState = {
  config: {
    limits: {
      maxTime: MAX_TIME,
      warnTime: MAX_TIME - MAX_TIME / 5
    },
    button1: "man",
    button2: "notman",
    locale: null
  },
  current: {
    ticker: null,
    startedAt: null,
    category: null,
    alreadyTalked: false,
    isOrganizer: false
  },
  speeches: [],
  system: {
    updateAvailable: false,
    newServiceWorker: null
  }
};

function config(state = initialState.config, action) {
  switch (action.type) {
    case CHANGE_CONFIG:
      return {
        ...state,
        ...action.newConfig
      };
    default:
      return state;
  }
}

function current(state = initialState.current, action) {
  switch (action.type) {
    case START_TALKING:
      return {
        ticker: 0,
        startedAt: action.timestamp,
        category: action.category,
        alreadyTalked: false,
        isOrganizer: false
      };
    case STOP_TALKING:
      return {
        ticker: null,
        startedAt: null,
        category: null
      };
    case TICK:
      return {
        ...state,
        ticker: action.timestamp - state.startedAt
      };
    case ALREADY_TALKED:
      return {
        ...state,
        alreadyTalked: action.value
      };
    case IS_ORGANIZER:
      return {
        ...state,
        isOrganizer: action.value
      };
    case RESET:
      return initialState.current;
    default:
      return state;
  }
}

function speeches(state = initialState.speeches, action) {
  switch (action.type) {
    case START_TALKING:
    case STOP_TALKING:
      if (action.speech === null) {
        return state;
      }
      return [...state, action.speech];
    case RESET:
      return initialState.speeches;
    default:
      return state;
  }
}

function system(state = initialState.system, action) {
  switch (action.type) {
    case UPDATE_AVAILABLE:
      return {
        updateAvailable: true,
        newServiceWorker: action.newServiceWorker
      };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  config,
  current,
  speeches,
  system
});

export default rootReducer;
