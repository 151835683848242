import React, { Component } from "react";
import Button from "@material-ui/core/Button";

class SpeakerButton extends Component {
  onClick = e =>
    this.props.selected
      ? this.props.stopTalking()
      : this.props.startTalking(this.props.category);

  render() {
    return (
      <Button
        variant={this.props.selected ? "contained" : "outlined"}
        color="primary"
        size="large"
        onClick={this.onClick}
      >
        {this.props.text}
      </Button>
    );
  }
}

export default SpeakerButton;
