import { connect } from "react-redux";
import { tick } from "../actions";
import Ticker from "../components/Ticker";

const mapStateToProps = (state, ownProps) => {
  return {
    running: state.current.ticker !== null,
    startedAt: state.current.startedAt
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    tick: () => dispatch(tick())
  };
};

const ConnectedTicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(Ticker);

export default ConnectedTicker;
