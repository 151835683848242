import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { defineMessages, FormattedMessage } from "react-intl";
import { messages as buttonMessages } from "./SpeakerButtons";

const styles = theme => ({
  title: {
    marginTop: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 135
  }
});

const messages = defineMessages({
  title: {
    id: "configbuttonlabel.title",
    defaultMessage: "Catégories"
  },
  label1: {
    id: "configbuttonlabel.label1",
    defaultMessage: "First button:"
  },
  label2: {
    id: "configbuttonlabel.label2",
    defaultMessage: "Second button:"
  }
});

function ConfigButtonLabel(props) {
  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name;
    props.onChange({
      [name]: value
    });
  };
  const { classes } = props;

  return (
    <React.Fragment>
      <Typography variant="subtitle1" className={classes.title}>
        <FormattedMessage {...messages.title} />
      </Typography>

      <FormControl className={classes.formControl} disabled>
        <InputLabel id="settings-buttonlabel-1-label">
          <FormattedMessage {...messages.label1} />
        </InputLabel>
        <Select
          labelId="settings-buttonlabel-1-label"
          id="settings-buttonlabel-1"
          name="button1"
          value={props.config.button1}
          onChange={handleChange}
        >
          {["man"].map(label => (
            <MenuItem value={label} key={label}>
              <FormattedMessage {...buttonMessages[label]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel id="settings-buttonlabel-2-label">
          <FormattedMessage {...messages.label2} />
        </InputLabel>
        <Select
          labelId="settings-buttonlabel-2-label"
          id="settings-buttonlabel-2"
          name="button2"
          value={props.config.button2}
          onChange={handleChange}
        >
          {["notman", "woman"].map(label => (
            <MenuItem value={label} key={label}>
              <FormattedMessage {...buttonMessages[label]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
}

export default withStyles(styles)(ConfigButtonLabel);
