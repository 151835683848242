import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SpeakerButton from "./SpeakerButton";

export const messages = defineMessages({
  man: {
    id: "speakerbuttons.man",
    defaultMessage: "A man"
  },
  notman: {
    id: "speakerbuttons.notman",
    defaultMessage: "Not a man"
  },
  woman: {
    id: "speakerbuttons.woman",
    defaultMessage: "A woman"
  }
});

function SpeakerButtons(props) {
  return (
    <React.Fragment>
      <Grid item xs={6}>
        <Typography align="right">
          <SpeakerButton
            category={props.button1}
            isNew={true}
            text={props.intl.formatMessage(messages[props.button1])}
            selected={props.button1 === props.currentCategory}
            startTalking={props.startTalking}
            stopTalking={props.stopTalking}
          />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography align="left">
          <SpeakerButton
            category={props.button2}
            isNew={true}
            text={props.intl.formatMessage(messages[props.button2])}
            selected={props.button2 === props.currentCategory}
            startTalking={props.startTalking}
            stopTalking={props.stopTalking}
          />
        </Typography>
      </Grid>
    </React.Fragment>
  );
}

export default injectIntl(SpeakerButtons);
