import { connect } from "react-redux";
import { reset } from "../actions";
import ResetButton from "../components/ResetButton";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClick: () => dispatch(reset())
  };
};

const ConnectedResetButton = connect(
  null,
  mapDispatchToProps
)(ResetButton);

export default ConnectedResetButton;
