import { connect } from "react-redux";
import { alreadyTalked } from "../actions";
import AlreadyTalkedButton from "../components/AlreadyTalkedButton";

const mapStateToProps = (state, ownProps) => {
  const disabled =
    // nobody is speaking
    state.current.category === null ||
    // first speech in that category
    state.speeches.filter(speech => speech.category === state.current.category)
      .length === 0;
  return {
    disabled,
    hasAlreadyTalked: !disabled && state.current.alreadyTalked
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setAlreadyTalked: value => dispatch(alreadyTalked(value))
  };
};

const ConnectedAlreadyTalkedButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlreadyTalkedButton);

export default ConnectedAlreadyTalkedButton;
