import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";

const styles = theme => ({
  title: {
    marginBottom: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 70
  },
  noLimitButton: {
    marginTop: theme.spacing(2), // for smaller screens where the button wraps
    marginLeft: theme.spacing(1),
    verticalAlign: "bottom",
    textTransform: "lowercase"
  }
});

const messages = defineMessages({
  nolimit: {
    id: "configtimerlimit.nolimit",
    defaultMessage: "no limit"
  },
  buttontext: {
    id: "configtimerlimit.buttontext",
    defaultMessage: "{min}:{sec} max"
  },
  title: {
    id: "configtimerlimit.title",
    defaultMessage: "Maximum speech time"
  },
  minutes: {
    id: "configtimerlimit.minutes",
    defaultMessage: "Minutes"
  },
  seconds: {
    id: "configtimerlimit.seconds",
    defaultMessage: "Seconds"
  }
});

class ConfigTimerLimit extends Component {
  setMaxTime = value => {
    this.props.onChange({
      limits: {
        maxTime: value,
        warnTime: value - Math.round(value / 5)
      }
    });
  };

  handleChange = e => {
    let maxTime;
    let value = e.target.value ? parseInt(e.target.value, 10) : 0;
    if (e.target.name === "minutes") {
      maxTime = value * 60 + this.getSeconds();
    } else if (e.target.name === "seconds") {
      maxTime = this.getMinutes() * 60 + value;
    }
    if (maxTime < 0) {
      maxTime = 0;
    }
    this.setMaxTime(maxTime);
  };

  handleNoLimit = e => {
    this.setMaxTime(0);
  };

  getMinutes = () => Math.trunc(this.props.config.limits.maxTime / 60);

  getSeconds = () => this.props.config.limits.maxTime % 60;

  render() {
    const { classes } = this.props;
    const minutes = String(this.getMinutes());
    const seconds = String(this.getSeconds()).padStart(2, "0");
    return (
      <React.Fragment>
        <Typography variant="subtitle1" className={classes.title}>
          <FormattedMessage {...messages.title} />
        </Typography>
        <TextField
          id="maxminutes"
          name="minutes"
          label={this.props.intl.formatMessage(messages.minutes)}
          type="number"
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
          value={minutes}
          onChange={this.handleChange}
        />
        <TextField
          id="maxseconds"
          name="seconds"
          label={this.props.intl.formatMessage(messages.seconds)}
          type="number"
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            step: 10
          }}
          value={seconds}
          onChange={this.handleChange}
        />
        <Button
          onClick={this.handleNoLimit}
          className={classes.noLimitButton}
          variant="contained"
          size="small"
        >
          <Icon style={{ marginRight: "0.3rem" }} fontSize="small">
            block
          </Icon>
          <FormattedMessage {...messages.nolimit} />
        </Button>
      </React.Fragment>
    );
  }
}

export default injectIntl(withStyles(styles)(ConfigTimerLimit));
