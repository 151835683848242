import { connect } from "react-redux";
import { changeConfig } from "../actions";
import Settings from "../components/Settings";

const mapStateToProps = (state, ownProps) => {
  return {
    config: state.config
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: value => dispatch(changeConfig(value))
  };
};

const ConnectedSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);

export default ConnectedSettings;
