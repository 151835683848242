import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./store";
import App from "./components/App";
import IntlContainer from "./containers/IntlContainer";
import registerServiceWorker from "./registerServiceWorker";
import { notifyNewUpdate } from "./actions";

if (!Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill");
  require("@formatjs/intl-pluralrules/dist/locale-data/en");
  require("@formatjs/intl-pluralrules/dist/locale-data/fr");
}

if (!Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-relativetimeformat/dist/locale-data/en");
  require("@formatjs/intl-relativetimeformat/dist/locale-data/fr");
}

const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <IntlContainer>
        <App />
      </IntlContainer>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

const onSWUpdated = newServiceWorker => {
  store.dispatch(notifyNewUpdate(newServiceWorker));
};
registerServiceWorker(onSWUpdated);
