import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { defineMessages, FormattedMessage } from "react-intl";

const messages = defineMessages({
  text: {
    id: "resetbutton.text",
    defaultMessage: "Reset"
  }
});

class ResetButton extends Component {
  render() {
    return (
      <Button color="secondary" size="small" onClick={this.props.onClick}>
        <Icon fontSize="inherit" style={{ marginRight: "0.3em" }}>
          cancel
        </Icon>
        <FormattedMessage {...messages.text} />
      </Button>
    );
  }
}

export default ResetButton;
