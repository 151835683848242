import { connect } from "react-redux";
import UpdateNotification from "../components/UpdateNotification";

const mapStateToProps = state => {
  return {
    updateAvailable: state.system.updateAvailable,
    newServiceWorker: state.system.newServiceWorker
  };
};

const ConnectedUpdateNotification = connect(mapStateToProps)(
  UpdateNotification
);

export default ConnectedUpdateNotification;
