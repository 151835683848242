import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";
import { SUPPORTED_LOCALES } from "../containers/IntlContainer";

const styles = theme => ({
  title: {
    marginTop: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
});

const messages = defineMessages({
  title: {
    id: "configlocale.title",
    defaultMessage: "Language"
  },
  label: {
    id: "configlocale.label",
    defaultMessage: "Choose a language:"
  },
  en: {
    id: "configlocale.en",
    defaultMessage: "English"
  },
  fr: {
    id: "configlocale.fr",
    defaultMessage: "French"
  }
});

function ConfigLocale(props) {
  const handleChange = e => {
    const value = e.target.value;
    props.onChange({
      locale: value
    });
  };
  const { classes } = props;

  return (
    <React.Fragment>
      <Typography variant="subtitle1" className={classes.title}>
        <FormattedMessage {...messages.title} />
      </Typography>

      <FormControl className={classes.formControl}>
        <InputLabel id="settings-locale-label">
          <FormattedMessage {...messages.label} />
        </InputLabel>
        <Select
          labelId="settings-locale-label"
          id="settings-locale"
          value={props.config.locale || ""}
          onChange={handleChange}
        >
          {SUPPORTED_LOCALES.map(locale => (
            <MenuItem value={locale} key={locale}>
              <FormattedMessage {...messages[locale]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
}

export default injectIntl(withStyles(styles)(ConfigLocale));
