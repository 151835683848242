import { connect } from "react-redux";
import Stats from "../components/Stats";

const computeDuration = speeches =>
  speeches.reduce((total, current) => total + current.duration, 0);

const computeSpeakers = speeches =>
  speeches.reduce(
    (total, current) => total + (current.alreadyTalked ? 0 : 1),
    0
  );

const mapStateToProps = (state, ownProps) => {
  const privilegedSpeeches = state.speeches.filter(
    speech => speech.category === state.config.button1
  );
  const minoritySpeeches = state.speeches.filter(
    speech => speech.category === state.config.button2
  );
  let stats = {
    man: {
      duration: computeDuration(privilegedSpeeches),
      speeches: privilegedSpeeches.length,
      speakers: computeSpeakers(privilegedSpeeches)
    },
    [state.config.button2]: {
      duration: computeDuration(minoritySpeeches),
      speeches: minoritySpeeches.length,
      speakers: computeSpeakers(minoritySpeeches)
    }
  };
  if (state.current.ticker !== null) {
    stats[state.current.category].duration += state.current.ticker;
    stats[state.current.category].speeches += 1;
    stats[state.current.category].speakers += state.current.alreadyTalked
      ? 0
      : 1;
  }
  return {
    stats,
    button1: state.config.button1,
    button2: state.config.button2
  };
};

const ConnectedStats = connect(mapStateToProps)(Stats);

export default ConnectedStats;
