import { connect } from "react-redux";
import Timer from "../components/Timer";

const mapStateToProps = (state, ownProps) => {
  return {
    ticker: state.current.ticker,
    maxTime: state.config.limits.maxTime,
    warnTime: state.config.limits.warnTime
  };
};

const ConnectedTimer = connect(mapStateToProps)(Timer);

export default ConnectedTimer;
