import { connect } from "react-redux";
import { startTalking, stopTalking } from "../actions";
import SpeakerButtons from "../components/SpeakerButtons";

const mapStateToProps = state => {
  return {
    currentCategory: state.current.category,
    button1: state.config.button1,
    button2: state.config.button2
  };
};

const ConnectedSpeakerButtons = connect(mapStateToProps, {
  startTalking,
  stopTalking
})(SpeakerButtons);

export default ConnectedSpeakerButtons;
