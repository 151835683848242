import { connect } from "react-redux";
import { setOrganizer } from "../actions";
import IsOrganizerButton from "../components/IsOrganizerButton";

const mapStateToProps = (state, ownProps) => {
  const disabled = state.current.category === null;
  return {
    disabled,
    isOrganizer: !disabled && state.current.isOrganizer
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setOrganizer: value => dispatch(setOrganizer(value))
  };
};

const ConnectedIsOrganizerButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(IsOrganizerButton);

export default ConnectedIsOrganizerButton;
