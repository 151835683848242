export const START_TALKING = "START_TALKING";
export const STOP_TALKING = "STOP_TALKING";
export const ALREADY_TALKED = "ALREADY_TALKED";
export const IS_ORGANIZER = "IS_ORGANIZER";
export const TICK = "TICK";
export const CHANGE_CONFIG = "CHANGE_CONFIG";
export const RESET = "RESET";
export const UPDATE_AVAILABLE = "UPDATE_AVAILABLE";

function currentSpeech(state) {
  const current = state.current;
  if (current.category === null) {
    return null;
  }
  const now = Math.round(Date.now() / 1000),
    duration = now - current.startedAt,
    overtime = state.config.limits.maxTime
      ? duration > state.config.limits.maxTime
      : false,
    startDate = new Date(current.startedAt * 1000);
  return {
    timestamp: startDate.toISOString(),
    category: current.category,
    duration,
    alreadyTalked: current.alreadyTalked,
    isOrganizer: current.isOrganizer,
    overtime
  };
}

export function startTalking(category) {
  return (dispatch, getState) => {
    return dispatch({
      type: START_TALKING,
      category,
      timestamp: Math.round(Date.now() / 1000),
      speech: currentSpeech(getState())
    });
  };
}

export function stopTalking() {
  return (dispatch, getState) => {
    const state = getState();
    return dispatch({
      type: STOP_TALKING,
      speech: currentSpeech(state)
    });
  };
}

export function alreadyTalked(value) {
  return (dispatch, getState) => {
    const category = getState().current.category;
    if (category === null) {
      return null;
    }
    return dispatch({
      type: ALREADY_TALKED,
      value,
      category
    });
  };
}

export function setOrganizer(value) {
  return (dispatch, getState) => {
    const category = getState().current.category;
    if (category === null) {
      return null;
    }
    return dispatch({
      type: IS_ORGANIZER,
      value,
      category
    });
  };
}

export function tick() {
  return {
    type: TICK,
    timestamp: Math.round(Date.now() / 1000)
  };
}

export function changeConfig(newConfig) {
  return {
    type: CHANGE_CONFIG,
    newConfig
  };
}

export function reset() {
  return {
    type: RESET
  };
}

export function notifyNewUpdate(newServiceWorker) {
  return {
    type: UPDATE_AVAILABLE,
    newServiceWorker,
    timestamp: Math.round(Date.now() / 1000)
  };
}
